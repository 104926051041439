import React, { useState } from 'react';
//import { css, StyleSheet } from 'aphrodite/no-important';
import StepNavigatorContainer from 'components/shortComponents/stepNavigator/StepNavigatorContainer';
import StepIndicatorContainer from 'components/shortComponents/stepIndicator/StepIndicatorContainer';
import { IAppState } from 'types/appState.type';
import { useTranslation } from 'react-i18next';
import { projectNamespace } from 'i18n';
import {
	renderMultilineToParagraphs,
	getNativeLongFormState,
} from 'utils/renderHelpers';

import styles from './TaskContent.module.scss';
import { TourStep } from 'types/schema.type';

//const styles = StyleSheet.create({
//    title: {
//        color: '#1C57EC',
//        marginBottom: '24px',
//    },
//    content: {
//        fontSize: '14px',
//        lineHeight: '21px',
//        width: '350px',
//        transition: 'all 0.5s ease',
//        position: 'absolute',
//    },
//    contentHidden: {
//        pointerEvents: 'none',
//        transition: 'all 0.5s ease',
//        opacity: 0,
//        position: 'relative',
//        flex: 1,
//        maxHeight: 'auto',
//    },
//    contentHiddenPrev: {
//        flex: 0,
//        maxHeight: 0,
//    },
//    contentHiddenNext: {
//        flex: 0,
//        maxHeight: 0,
//    },
//    contentClosed: {
//        'max-height': 0,
//        opacity: 0,
//    },
//    contentOpen: {
//        'max-height': '1000px',
//        opacity: 1,
//    },
//    taskContainer: {
//        // borderLeft: '2px solid #1C57EC',
//        // paddingLeft: '20px',
//        position: 'relative',
//        maxHeight: 'auto',
//        transition: 'max-height 1s ease',
//        margin: '36px 0',
//    },
//    stepContainer: {
//        minHeight: '210px',
//        display: 'flex',
//        flexDirection: 'row',
//        alignItems: 'flex-start',
//        justifyContent: 'flex-start',
//    },
//    stepPrev: {
//        pointerEvents: 'none',
//        left: '-100%',
//        opacity: 0,
//    },
//    stepSelected: {
//        // left: '0',
//    },
//    stepNext: {
//        pointerEvents: 'none',
//        left: '100%',
//        opacity: 0,
//    },
//    fadeIn: {
//        animationName: {
//            '0%': {
//                opacity: 0,
//            },
//            '100%': {
//                opacity: 1,
//            },
//        },
//        animationDuration: '2.5s',
//        animationIterationCount: 1,
//    },
//});

export const TaskContent = (props: IAppState) => {
	const [open, setOpen] = useState(false);
	const { stepState, tourState, sectionState } = props;
	const { selectedTourId } = tourState;
	const { selectedSection } = sectionState;

	const selectedStep = Object.assign(new TourStep(), stepState.selectedStep);
	const selectedStepId = selectedStep.stepNumber;

	const isNativeLongForm = getNativeLongFormState(props);

	const namespaceString = isNativeLongForm ? 'tours' : 'scenarios';
	const tourNamespace = `${namespaceString}/${selectedTourId}`;

	const { t } = useTranslation([projectNamespace, tourNamespace]);

	setTimeout(() => {
		setOpen(true);
	}, 1);

	const title = t(
		`${namespaceString}/${selectedTourId}:${selectedSection.sectionName}`
	);
	return (
		<div className={`${styles.taskContainer}`}>
			<h2 className={`${styles.title}`}>{title}</h2>
			<div className={`${styles.stepContainer} ${styles.fadeIn}`}>
				{selectedSection.steps.map((step, index) => (
					<div key={index + 1}>
						<div
							className={`${styles.content} ${
								open ? styles.contentOpen : styles.contentClosed
							}
                                        ${
																					index + 1 === selectedStepId
																						? styles.stepSelected
																						: index + 1 < selectedStepId
																						? styles.stepPrev
																						: styles.stepNext
																				}`}
						>
							{selectedStep.stepDetails.length > 0 &&
								renderMultilineToParagraphs(
									selectedStep.stepDetails,
									t,
									tourNamespace
								)}
						</div>
						<div
							className={`${styles.content} ${styles.contentHidden} ${
								index + 1 < selectedStepId
									? styles.contentHiddenPrev
									: index + 1 > selectedStepId
									? styles.contentHiddenNext
									: ''
							}`}
						>
							{selectedStep.stepDetails.length > 0 &&
								renderMultilineToParagraphs(
									selectedStep.stepDetails,
									t,
									tourNamespace
								)}
						</div>
					</div>
				))}
			</div>

			<>
				<StepNavigatorContainer />
				{selectedStep.isDefaultCoordinates() && <StepIndicatorContainer />}
			</>
		</div>
	);
};
