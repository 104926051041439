import React from 'react';
//import {css, StyleSheet} from 'aphrodite/no-important';
import { Link } from 'react-router-dom';
import { TaskContent } from 'components/shortComponents/taskContent/TaskContentComponent';
import { TaskSectionGrouping } from 'components/shortComponents/taskGrouping/TaskSectionGrouping';
import { IAppState } from 'types/appState.type';
import { IFocusElement } from 'types/project.type';
import { GetJsllIdPrefix } from 'utils/jsllTaggingUtils';
import { Tour } from 'types/schema.type';
import { useTranslation } from 'react-i18next';
import { projectNamespace } from 'i18n';

import styles from './StepItem.module.scss';

export interface IStepItemState {
	landingAnimated: boolean;
}

export interface IStepItemProps {
	setFocusElementAction(focusElementAction: IFocusElement): void;
}

export interface IStepItemWithDispatch extends IStepItemProps, IAppState {}

//const styles = StyleSheet.create({
//    landingPageContentContainer: {
//        display: 'flex',
//        flexDirection: 'column',
//        alignItems: 'flex-end',
//        justifyContent: 'center',
//        transition: 'all 0.5s linear',
//        width: '400px',
//        opacity: 1,
//    },
//    stepsContentContainer: {
//        transition: 'all 0.5s linear',
//        width: '400px',
//    },
//    stepsContentHidden: {
//        flex: 0,
//        width: 0,
//        opacity: 0,
//        overflow: 'hidden',
//    },
//    stepsContent: {
//        position: 'relative',
//        color: '#161616',
//        width: '350px',
//        transition: 'all 0.5s 0.5s ease',
//    },
//    sectionTitle: {
//        'text-decoration': 'none',
//        color: '#525252',
//        transition: 'all 0.5s 0.5s ease',
//    },
//});

export const StepItem = (props: IStepItemWithDispatch) => {
	const { localeState, tourState, collectionState, sectionState } = props;
	const { selectedSection } = sectionState;
	const { selectedTourId } = tourState;
	const { selectedCollection } = collectionState;
	const selectedTour = tourState.selectedTour
		? tourState.selectedTour
		: new Tour();
	const { selectedLocale } = localeState;
	const { t } = useTranslation([
		projectNamespace,
		`scenarios/${selectedTourId}`,
	]);

	const sections = selectedTour ? selectedTour.sections : [];
	const sectionNames = sections.map(({ sectionName }) => sectionName);
	const selectedSectionId = selectedSection.sectionNumber;

	const getTaskContentOrLink = (title: String, index: number) => {
		if (index + 1 === selectedSectionId) {
			return <TaskContent {...(props as IAppState)} key={index + 1} />;
		} else {
			const jsllIdPrefix = GetJsllIdPrefix(props, t);
			const taskId = `${jsllIdPrefix}-title`;

			return (
				<Link
					id={taskId + index}
					data-bi-name={taskId}
					tabIndex={0}
					key={index + 1}
					to={`/${selectedLocale}/guidedtour/${
						selectedCollection!.id
					}/${selectedTourId}/${index + 1}/1`}
					className={`${styles.sectionTitle}`}
					onClick={() => {
						!selectedTour.sections[index].steps[0].isDefaultCoordinates()
							? props.setFocusElementAction(IFocusElement.Bubble)
							: props.setFocusElementAction(IFocusElement.Next);
					}}
				>
					<h2>{t(`scenarios/${selectedTourId}:${title}`)}</h2>
				</Link>
			);
		}
	};

	return (
		<div className={`${styles.stepsContentContainer}`}>
			<div className={`${styles.stepsContent}`}>
				{/*Tours with sections for tasks*/}
				{selectedTour &&
					selectedTour.scenarioTaskGrouping.scenarioGroupsTasks && (
						<TaskSectionGrouping
							{...(props as IAppState)}
							linkOrContentFunction={getTaskContentOrLink}
							setFocusElementActionFunction={props.setFocusElementAction}
						/>
					)}
				{/*Non-sectioned tours*/}
				{selectedTour &&
					!selectedTour.scenarioTaskGrouping.scenarioGroupsTasks &&
					sectionNames &&
					sectionNames.map((title, index) => {
						return getTaskContentOrLink(title, index);
					})}
			</div>
		</div>
	);
};
